@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Besley:ital,wght@0,400..900;1,400..900&display=swap");
html {
  scroll-behavior: smooth;
}
body {
  background-color: #20242d;
  font-family: "Besley", sans-serif;
  font-weight: 800;
}
span,
.primary-color {
  background-image: linear-gradient(to right, #ff5722, #ff8722);
  -webkit-background-clip: text;
  color: transparent;
}

.bg-primary-color {
  background-color: linear-gradient(to right, #ff5722, #ff8722);
}
.img-profile {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;

  margin: auto;

  background-image: linear-gradient(19deg, #b63a14 0%, #ff8722 100%);
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.2);
  animation: morphing 12s infinite ease-in-out;
  overflow: hidden;
}
button {
  position: relative;
  padding: 10px 20px;
  border-radius: 7px;
  border: 2px solid #ff8722;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 2px;
  background: transparent;
  color: #fff;
  overflow: hidden;
  box-shadow: 0 0 0 0 transparent;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.icon:hover {
  filter: drop-shadow(0 0 7px #ff8722);
}
button:hover {
  background: #ff8722;
  box-shadow: 0 0 30px 5px #ff8722;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

button:hover::before {
  -webkit-animation: sh02 0.5s 0s linear;
  -moz-animation: sh02 0.5s 0s linear;
  animation: sh02 0.5s 0s linear;
}

button::before {
  content: "";
  display: block;
  width: 0px;
  height: 86%;
  position: absolute;
  top: 7%;
  left: 0%;
  opacity: 0;
  background: #fff;
  box-shadow: 0 0 50px 30px #fff;
  -webkit-transform: skewX(-20deg);
  -moz-transform: skewX(-20deg);
  -ms-transform: skewX(-20deg);
  -o-transform: skewX(-20deg);
  transform: skewX(-20deg);
}

@keyframes sh02 {
  from {
    opacity: 0;
    left: 0%;
  }

  50% {
    opacity: 1;
  }

  to {
    opacity: 0;
    left: 100%;
  }
}

button:active {
  box-shadow: 0 0 0 0 transparent;
  -webkit-transition: box-shadow 0.2s ease-in;
  -moz-transition: box-shadow 0.2s ease-in;
  transition: box-shadow 0.2s ease-in;
}

@keyframes morphing {
  0% {
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
    box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.2);
  }
  25% {
    border-radius: 58% 42% 75% 25% / 76% 46% 54% 24%;
  }
  50% {
    border-radius: 50% 50% 33% 67% / 55% 27% 73% 45%;
    box-shadow: -10px -5px 50px rgba(0, 0, 0, 0.2);
  }
  75% {
    border-radius: 33% 67% 58% 42% / 63% 68% 32% 37%;
  }
}
@keyframes moving {
  0% {
    transform: translateY(0px);
  }
  
  100% {
    transform: translateY(-1000px);
  }
}
.form__group {
  position: relative;
  padding: 20px 0 0;
}

.form__field {
  font-family: inherit;
  width: 100%;
  border: none;
  border-bottom: 2px solid #ff8722;
  outline: 0;
  font-size: 17px;
  color: #fff;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;
}

.form__field::placeholder {
  color: transparent;
}

.form__field:placeholder-shown ~ .form__label {
  font-size: 17px;
  cursor: text;
  top: 30px;
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 17px;
  color: #9b9b9b;
  pointer-events: none;
}

.form__field:focus {
  padding-bottom: 6px;
  font-weight: 700;
  border-width: 3px;
  border-image: linear-gradient(to right, #ff8722, #ff8722);
  border-image-slice: 1;
}

.form__field:focus ~ .form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 17px;
  color: #ff8722;
  font-weight: 700;
}

/* reset input */
.form__field:required,
.form__field:invalid {
  box-shadow: none;
}

/* Animaciones y estilo de las burbujas */
.bubbles {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Asegurar que estén detrás del contenido */
  pointer-events: none; /* Evitar interacción con las burbujas */
  overflow: hidden;
  opacity: .6;

}

.bubbles li {
  position: absolute;
  list-style: none;
  display: block;
  background-color: #ff8722;
  border-radius: 50%; /* Inicialmente redondas */
  bottom: -150px; /* Comienza fuera de la pantalla */
  animation: bubble-rise 12s infinite ease-in-out, morphing 8s infinite ease-in-out;
}

/* Animación de movimiento hacia arriba */
@keyframes bubble-rise {
  0% {
    transform: translateY(0);
    opacity: 0.8;
  }
  50% {
    opacity: 0.9;
  }
  100% {
    transform: translateY(-110vh); /* Fuera del viewport */
    opacity: 0;
  }
}

/* Estilo para cada burbuja */
.bubbles li:nth-child(1) {
  width: 40px;
  height: 40px;
  left:30% ;
  animation-delay: 0s;
  animation-duration: 10s;
}

.bubbles li:nth-child(2) {
  width: 50px;
  height: 50px;
  left: 85%;
  animation-delay: 2s;
  animation-duration: 12s;
}

.bubbles li:nth-child(3) {
  width: 15px;
  height: 15px;
  left: 50%;
  animation-delay: 4s;
  animation-duration: 9s;
}

.bubbles li:nth-child(4) {
  width: 30px;
  height: 30px;
  left: 70%;
  animation-delay: 6s;
  animation-duration: 11s;
}

.bubbles li:nth-child(5) {
  width: 20px;
  height: 20px;
  
  left:20% ;
  animation-delay: 1s;
  animation-duration: 13s;
}
